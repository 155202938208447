import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import useSettings from "../../../../../hooks/settings.hook";
import { initVAT, schemesType, textAlert, TYPES, vatSettingsType } from "../utils";
import { Form as FormikForm } from "formik";
import DSplashScreen from "../../../../components/SplashScreen/DSplashScreen";
import { useModal } from "../../../../../hooks/modal.hook";
import { actionsCOG } from "../../../Dashboard/OrdersRefunds/components";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import DTabs from "../../../../components/DTabs/DTabs";
import { tabs } from "../components";
import _ from 'lodash';
import HistoryInfo from "./HistoryInfo";
import classNames from "clsx";

export const schemes = (data) => data.vat_schemes ?  [...data.vat_schemes] : [];

const VATSettings = props => {
  const {initData = {}, setInitData, form, setSubmitCustomProps} = props;
  const {
    vatSettings,
    updateVATSetting,
    vatLoading,
  } = useSettings({ vat: true });
  const [openDialog, toggleDialog] = useModal();
  const [updateData, setUpdateData] = useState({});

  const [currentTab, setTab] = useState("current");
  const [currentVATTab, setVATTab] = useState("current");
  const [activeVATData, setActiveVATData] = useState({});
  const [currentVATTabData, setCurrentVATTabData] = useState({});
  const [historyVATData, setHistoryVATData] = useState([]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    let allVATSettings = [...vatSettings];
    let currentVATSettings = allVATSettings.pop();
    setActiveVATData(currentVATSettings);
    setHistoryVATData(allVATSettings);
    setCurrentVATTabData(currentVATSettings);
  }, [vatSettings]);

  useEffect(() => {
    if (!_.isEmpty(currentVATTabData)) {
      const schemes = currentVATTabData.vat_schemes ?  [...currentVATTabData.vat_schemes] : [];
      let currentScheme = initVAT;
      if (schemes?.length !== 0 && currentVATTabData.is_vat_registered && currentVATTab !== "historic") {
        currentScheme = schemes?.pop();
      }

      let data = {
        ...currentVATTabData,
        registration_date: currentVATTabData.registration_date || null,
        deregistration_date: null,
        global_vat_current: [
          {
            ...currentVATTabData,
            registration_date: currentVATTabData.registration_date || null,
            deregistration_date: null,
          }
        ],
        global_vat_historic: historyVATData,
        schemes_current: currentScheme ? [currentScheme] : [],
        schemes_historic: schemes,
        schemes_new: [initVAT],
      };
      setInitData({...initData, ...form.values, ...data});
    }
  }, [currentVATTabData]);

  const handleChangeVATTab = (event, newValue) => {
    const data = newValue === "current" ? activeVATData : {};
    setCurrentVATTabData(data);
    setVATTab(newValue);
    setTab(newValue);
  };

  const handleDialog = (props) => {
    toggleDialog();
    setUpdateData(props);
  };

  const onConfirmDialog = () => {
    toggleDialog();
    updateVATSetting(updateData);
  };

  const handleInfo = ({ values }) => () => {
    setCurrentVATTabData(values);
  };

  const isDisplayHistoryVATSchemes =
    currentVATTab === "historic" && currentVATTabData.id && activeVATData.id !== currentVATTabData.id;

  const qtySchemes = currentVATTabData?.vat_schemes?.length;
  const currentQtySchemes = initData.is_vat_registered ? qtySchemes: qtySchemes + 1;

  const CURRENT_TAB_FORM_VALUES = 0;
  const currentValues = form.values?.global_vat_current?.[CURRENT_TAB_FORM_VALUES];
  const isShowSchemes = currentValues?.is_vat_registered && currentVATTab === "current";

  return (
    <>
      <FormikForm className="vat-settings__main-form">
        <Typography variant="h5" color="primary" className="settings__subtitle--main bottom-border">VAT Settings</Typography>
        <div className="d-splash-screen--wrap">
          {vatLoading && <DSplashScreen />}
          <div className="settings-main__vat__form">

            {/*general VAT*/}
            <DTabs
              value={currentVATTab}
              tabsData={tabs({
                tabOptions: vatSettingsType({ qty: vatSettings?.length }),
                form,
                type: TYPES.vat,
                tab: currentVATTab,
                initData,
                dataName: `global_vat_${currentVATTab}`,
                extraContent: (props) => (
                  <HistoryInfo handleInfo={handleInfo} currentVATTabData={currentVATTabData} {...props} />
                )
              })}
              onChange={handleChangeVATTab}
              className={classNames({"historic-tab": currentVATTab === "historic"},"general-vat-settings")}
            />
            {/*general VAT end*/}

            {/*schemes*/}
            {(isShowSchemes || isDisplayHistoryVATSchemes) && (
              <>
                <Typography variant="h6" color="primary" className="settings__subtitle--main border-bottom">
                  Schemes
                </Typography>
                <DTabs
                  value={currentTab}
                  tabsData={tabs({
                    tabOptions: schemesType(currentVATTab, currentQtySchemes),
                    form,
                    type: TYPES.schemes,
                    tab: currentTab,
                    initData,
                    dataName: `schemes_${currentTab}`,
                  })}
                  onChange={handleChange}
                  className={classNames({"historic-tab": currentTab === "historic"},"scheme-settings")}
                />
              </>
            )}
            {/*schemes end*/}
          </div>
        </div>
        {currentTab !== "historic" && (
          <div className="settings__wrap-btn">
            <Button
              variant="contained"
              className="settings-main__vat__btn settings__btn"
              type="submit"
              onClick={() => setSubmitCustomProps({
                type: TYPES.vat,
                currentTab,
                handleDialog,
                successNote: "Success!",
              })}
              disabled={currentTab === "historic" || form.isSubmitting}
            >
              Update
            </Button>
          </div>
        )}
        <AlertDialog
          className="cog-edit__dialog"
          open={openDialog}
          onClose={toggleDialog}
          actions={actionsCOG({ onClose: toggleDialog, onConfirm: onConfirmDialog })}
          content={textAlert(form.values?.global_vat_current?.[0], initData)} // data of "current" vat tab
        />
      </FormikForm>
    </>
  )
};

export default VATSettings;
