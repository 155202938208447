import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField, Typography } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { resetPassword } from "../../crud/auth.crud";
import queryString from 'query-string';
import { formatErrors } from "./Registration";
import { useHistory } from "react-router-dom";


class ResetPassword extends Component {
  state = { isRequested: false };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;
    let token = queryString.parse(window.location.search).token;


    if (isRequested) {
      // const history = useHistory();
      // history.push('/auth/login');

    }

    return (
      <div className="kt-login__body reset-password">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <Typography className="onboarding__title">
              Reset Password
            </Typography>
          </div>
          <Formik
            initialValues={{
              new_password: "",
              confirm_new_password: "",
            }}
            validate={values => {
              const errors = {};

              if (!values.new_password) {
                errors.new_password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }
              if (!values.confirm_new_password) {
                errors.confirm_new_password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (values.new_password !== values.confirm_new_password) {
                errors.confirm_new_password =
                  "New Password and Confirm New Password didn't match.";
              }
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              const data = {};
              data.password = values.new_password;
              data.token = token;
              resetPassword(data)
                .then(() => {
                  this.setState({ isRequested: true });
                })
                .catch((error={}) => {
                  if (error.response) {
                    const { data } = error.response;
                    if (data && data.password) {
                      setStatus(formatErrors(data.password));
                    }
                  }
                  setSubmitting(false);
                  console.error(error)
                  // setStatus(
                  //   intl.formatMessage(
                  //     { id: "AUTH.VALIDATION.NOT_FOUND" },
                  //     { name: values.new_password }
                  //   )
                  // );
                });
            }}
          >
            {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
              <form onSubmit={handleSubmit} className="kt-form">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0">
                  <TextField
                    type="password"
                    margin="normal"
                    label="New Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="kt-width-full"
                    name="new_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.new_password}
                    helperText={touched.new_password && errors.new_password}
                    error={Boolean(touched.new_password && errors.new_password)}
                  />
                </div>
                <div className="form-group mb-0">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm New Password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="kt-width-full"
                    name="confirm_new_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirm_new_password}
                    helperText={touched.confirm_new_password && errors.confirm_new_password}
                    error={Boolean(touched.confirm_new_password && errors.confirm_new_password)}
                  />
                </div>

                <div className="kt-login__actions full-size">
                  <button
                    type="submit"
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>

                  <div className="kt-login__head">
                    <span className="kt-login__signup-label">Return to</span>
                    &nbsp;&nbsp;
                    <Link to="/auth/login" className="kt-link kt-login__signup-link">
                      Sign in
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ResetPassword)
);
