/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {toAbsoluteUrl} from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import IsBaseSubscriptionUser from "../../pages/auth/IsBaseSubscriptionUser";
import Button from "@material-ui/core/Button";
import useUsers from "../../hooks/users.hook";
import {ReactComponent as Arrow} from "../../../_metronic/layout/assets/layout-svg-icons/arrow.svg";
import {ReactComponent as SettingsIcon} from "../../../_metronic/layout/assets/layout-svg-icons/menu-icon/Settings.svg";
// import {ReactComponent as SwitchIcon} from "../../../_metronic/layout/assets/layout-svg-icons/menu-icon/Switch.svg";
import {ReactComponent as LogoutIcon} from "../../../_metronic/layout/assets/layout-svg-icons/menu-icon/logout.svg";
import {ReactComponent as Walk} from "../../media/icons/read.svg";
import {Typography} from "@material-ui/core";
import useSettings from "../../hooks/settings.hook";
import * as RoutesMap from "../../router/routesMap";

const userItems = [
  {
    title: "Settings",
    icon: <SettingsIcon className="kt-notification__icon"/>,
    link: RoutesMap.SETTINGS,
  },
  // {
  //   title: "Switch Company",
  //   icon: <SwitchIcon className="kt-notification__icon"/>,
  //   link: "/companies",
  // },
  {
    title: "Walkthrough",
    icon: <Walk className="kt-notification__icon"/>,
    link: "",
  }
];

const UserProfile = props => {
  const {user} = useUsers({fetch: false});
  const {onWalk} = useSettings({fetch: false});
  const {showAvatar} = props;
  const avatar = user.pic ? user.pic : toAbsoluteUrl('./media/d-icons/user-svgrepo-com.svg');
  const onWalkSet = () => onWalk();
  return (
    <Dropdown
      alignRight
      drop="down"
      className="kt-header__topbar-item kt-header__topbar-item--user d-header__topbar-item--dropdown"
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        {showAvatar && <img alt="Pic" src={avatar}/>}
        <Arrow />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm dropdown-menu--user">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div className="dropdown__content">
          <div className="kt-user-card kt-user-card--skin-dark d-user-card">
            <div className="kt-user-card__avatar">
              {showAvatar && <img alt="Pic" src={avatar}/>}
            </div>
            <div className="kt-user-card__name">{user.fullname}</div>
          </div>
          <div className="kt-notification">
            {userItems.map(({title, icon, link}) => {
              const buttonContent = <Dropdown.Item
                key={title}
                as="button"
                onClick={link ? undefined : onWalkSet}
              >
                <div className="kt-notification__item-icon ">
                  {icon}
                </div>
                <div className="kt-notification__item-details">
                  <Typography className="title">
                    {title}
                  </Typography>
                </div>
              </Dropdown.Item>
              return (link
                  ? (
                    <Link key={title} to={link} className="kt-notification__item kt-notification__item--clear">
                      {buttonContent}
                    </Link>
                  )
                  : buttonContent
              )
            })}

            {/*<IsBaseSubscriptionUser>*/}


              {/*<div className="kt-notification__item kt-notification__item--clear">*/}
              {/*<FormControlLabel*/}
              {/*className='space_checkboxes_label'*/}
              {/*labelPlacement="start"*/}
              {/*control={*/}
              {/*<Switch*/}
              {/*className="d-switch space_checkboxes"*/}
              {/*checked={isDarkMode}*/}
              {/*onChange={onSwitchTheme}*/}
              {/*inputProps={{'aria-label': `checkbox`}}*/}
              {/*/>*/}
              {/*}*/}
              {/*label="Light/Dark Theme"*/}
              {/*/>*/}
              {/*</div>*/}

            {/*</IsBaseSubscriptionUser>*/}

            <div className="kt-notification__custom">
              <Link to="/logout">
                <Button className="kt-notification__custom__btn">
                  <LogoutIcon/>
                  Logout
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserProfile;
