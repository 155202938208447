/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

 import React, {useEffect, useState} from "react";
 import { Redirect, Route, Switch, withRouter } from "react-router-dom";
 import { shallowEqual, useSelector } from "react-redux";
 import { useHistory } from 'react-router-dom';
 import HomePage from "../pages/home/HomePage";
 import AuthPage from "../pages/auth/AuthPage";
 import ErrorsPage from "../pages/errors/ErrorsPage";
 import LogoutPage from "../pages/auth/Logout";
 import { LayoutContextProvider } from "../../_metronic";
 import Layout from "../../_metronic/layout/Layout";
 import * as routerHelpers from "../router/RouterHelpers";
 import ThankYouPage from "../pages/auth/SubscriptionPage/ThankYouPage";
 import FinishPage from "../pages/auth/Onboarding/FinishPage";
 import SalesPage from "../pages/First/SalesPage";
 import {
   THANK_YOU_PAGE,
   ON_AMAZON,
   ON_LOGIN,
   ON_REGISTRATION,
   ON_SUBSCRIPTION_PAGE,
   ON_AMAZON_FINISH, SETTINGS, CONTACT
 } from "./routesMap";
 import OnboardingSubscribe from "../pages/auth/Onboarding/OnboardingSubscribe";
 import EmailRegistrationLogin from "../pages/auth/Onboarding/EmailRegistrationLogin";
 import OnboardingAmazon from "../pages/auth/Onboarding/OnboardingAmazon";
 import AmazonFinish from "../pages/auth/Onboarding/OnboardingAmazon/AmazonFinish";
 import RegistrationForm from "../pages/auth/components/RegistrationForm";
 import LoginForm from "../pages/auth/components/LoginForm";
 import {walkThroughSteps} from "../pages/home/utils";
 import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
 import {useThemeSwitcher} from "react-css-theme-switcher";
 import {Themes} from "../types/enums";
 import useSettings from "../hooks/settings.hook";
 import Contact from "../pages/First/Contact";
 
 export const isAmazonSetUp = (user) => {
   return user?.amazon_settings && user?.amazon_settings.some(({ seller_id }) => seller_id)
 };
 
 export const Dashboard = ({ user, userLastLocation }) => {
   if (isAmazonSetUp(user) && user?.subscription_paid) {
     return <Layout><HomePage userLastLocation={userLastLocation}/></Layout>
   }
 
   if (!user?.subscription_paid) {
    //  return <Redirect to={{pathname: ON_SUBSCRIPTION_PAGE}} />
   }
 
  //  return <Redirect to={{pathname: ON_AMAZON}} />
 };
 
 export const Routes = withRouter(({ history }) => {
   const [stepIndex, setStepIndex] = useState(0);
   const {currentTheme} = useThemeSwitcher();
   const [run, setRun] = useState(true);
   const historyRouter = useHistory();
   useEffect(() => {
     return historyRouter.listen(location => {
       routerHelpers.saveLastLocation(location.pathname);
     });
   }, [historyRouter]);
 
   
 
   const { isAuthorized, menuConfig, userLastLocation, user } = useSelector(
     ({ auth, urls, builder: { menuConfig } }) => ({
       menuConfig,
       isAuthorized: auth.user != null,
       userLastLocation: routerHelpers.getLastLocation(),
       user: auth.user
     }),
     shallowEqual
   );
 
   const {walk} = useSettings({fetch: false});
 
   const handleJoyrideCallback = data => {
     const { action, index, status, type, step } = data;
 
     if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
       // Update state to advance the tour
       const updateStep = index + (action === ACTIONS.PREV ? -1 : 1);
       setStepIndex(updateStep);
       if (step?.target === "[href='/settings']") {
         history.push(SETTINGS);
       }
     }
     else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
       // Need to set our running state to false, so we can restart if we click start again.
       setRun(false);
       localStorage.setItem("walk", "stop");
     }
   };
 
   useEffect(() => {
     const isRun = localStorage.getItem("walk") === "run";
     setRun(isRun);
   }, [walk]);
 
   return (
     /* Create `LayoutContext` from current `history` and `menuConfig`. */
     <LayoutContextProvider history={history} menuConfig={menuConfig}>
       <Joyride
         continuous
         hideCloseButton
         run={run}
         scrollToFirstStep
         showProgress
         size={walkThroughSteps.length}
         showSkipButton
         steps={walkThroughSteps}
         locale={{
           open: "",
           skip: 'Exit'
         }}
         styles={{
           options: {
             arrowColor: currentTheme === Themes.dark ? '#1B1F21' : "#FFFFFF",
             backgroundColor: currentTheme === Themes.dark ? '#1B1F21' : "#FFFFFF",
             primaryColor:  currentTheme === Themes.dark ?'#C380FF' : "#7B2CC2",
             textColor:  currentTheme === Themes.dark ?'#FFFFFF' : "#171725",
             width: 300,
             zIndex: 1000,
           },
           tooltipContent: {
             padding: 0,
           }
         }}
         stepIndex={stepIndex}
         callback={handleJoyrideCallback}
       />
       <Switch>
         <Route exact path="/" component={SalesPage} />
         <Route exact path={CONTACT}><Contact/></Route>
         <Route exact path={ON_SUBSCRIPTION_PAGE}>
           <OnboardingSubscribe />
         </Route>
         <Route exact path={ON_REGISTRATION}>
           <EmailRegistrationLogin
             title="Enter your personal and company details to sign up."
             redirectLink={ON_LOGIN}
             redirectText="Sign in"
             content={(props) => <RegistrationForm {...props} />}
           />
         </Route>
         <Route exact path={ON_LOGIN}>
           <EmailRegistrationLogin
             title="Enter your personal details to sign in."
             redirectLink={ON_REGISTRATION}
             redirectText="Sign up"
             content={(props) => <LoginForm {...props} />}
           />
         </Route>
 
         {!isAuthorized && <Route exact path="/auth/login"><AuthPage/></Route>}
         {!isAuthorized && <Route exact path="/auth/forgot-password"><AuthPage/></Route>}
         {!isAuthorized && <Route exact path="/auth/forgot-password/confirm"><AuthPage/></Route>}
         {/*{!isAuthorized && <Route exact path="/" component={SalesPage}/>}*/}
         {(isAuthorized && user?.subscription_paid) && <Route exact path={ON_AMAZON}><OnboardingAmazon/></Route>}
         {(isAuthorized && user?.subscription_paid) &&  <Route exact path={ON_AMAZON_FINISH}><AmazonFinish/></Route>}
 
         {/*<Route exact path="/auth"><Onboarding userLastLocation={userLastLocation} /></Route>*/}
         <Route path="/finish" component={FinishPage}/>
         <Route path={THANK_YOU_PAGE} component={ThankYouPage}/>
 
 
         <Route path="/error" component={ErrorsPage}/>
         <Route path="/logout" component={LogoutPage}/>
 
         {!isAuthorized ? (
           /* Redirect to `/auth` when user is not authorized */
           /*<Route path={ON_SUBSCRIPTION_PAGE}><OnboardingSubscribe /></Route>*/
           <Redirect to={{pathname: ON_SUBSCRIPTION_PAGE}} />
         ) : (
           <Dashboard userLastLocation={userLastLocation} user={user}/>
         )}
       </Switch>
     </LayoutContextProvider>
   );
 });
 