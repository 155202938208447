import React, {useState} from "react";
import {toAbsoluteUrl} from "../../../../_metronic";
import {Button, Typography} from "@material-ui/core";
import {Redirect} from "react-router";
import useUsers from "../../../hooks/users.hook";
import { useHistory } from "react-router-dom";

const FinishPage = () => {
  const { user } = useUsers();
  const history = useHistory();


  const [isRedirect, setRedirect] = useState(false);

  const onDash = () => {
    setRedirect(true);
  };

  if (isRedirect) {
    history.push('/dashboard');
  }

  return user?.synchronization_in_standby_mode ? (
      <div  className="onboarding finish">
          <img src={toAbsoluteUrl("/media/logos/new-logo.svg")} alt="logo" className="connect-doddled__logo" />
          <img src={toAbsoluteUrl("/media/d-icons/onboarding/finish.svg")} alt="confetti" className="connect-doddled__logo" />
          <img src={toAbsoluteUrl("/media/d-icons/onboarding/checked 1.svg")} alt="checked" className="connect-doddled__logo" />
          <Typography className="onboarding__title">Congratulations!</Typography>
          <Typography className="onboarding__title subtitle">You've not only successfully created your SKUify account but also completed the checkout process and connected your Amazon account.</Typography>
          <Typography className="onboarding__title subtitle">What's even better is that you now have a 30-day free trial and have secured your grandfathered price!</Typography>
          <Typography className="onboarding__title subtitle">You're currently in a queue for priority access, and over the next 24 hours, your account will sync with Amazon. We'll keep you posted as soon as that's completed.</Typography>
          <Typography className="onboarding__title subtitle">Welcome aboard SKUify! We're thrilled to have you with us.</Typography>
      </div>
      ) : (
      <div  className="onboarding finish">
        <img src={toAbsoluteUrl("/media/logos/new-logo.svg")} alt="logo" className="connect-doddled__logo" />
        <img src={toAbsoluteUrl("/media/d-icons/onboarding/finish.svg")} alt="confetti" className="connect-doddled__logo" />
        <img src={toAbsoluteUrl("/media/d-icons/onboarding/checked 1.svg")} alt="checked" className="connect-doddled__logo" />
        <Typography className="onboarding__title">Hooray!</Typography>
        <Typography className="onboarding__title subtitle">You have successfully set up your account!</Typography>
        <Button variant="contained" size="large" color="primary" onClick={onDash}>
          Go to Dashboard
        </Button>
      </div>
  )
};

export default FinishPage;