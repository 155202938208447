import React, { useRef } from 'react'
import * as PropTypes from 'prop-types'
import TableComponent from '../../../components/TableComponent/TableComponent'
import Typography from '@material-ui/core/Typography'
import { Portlet, PortletBody } from '../../../../partials/content/Portlet'
import OverlayComponent from '../../../components/OverlayComponent'
import useUsers from '../../../../hooks/users.hook'

const DashboardTable = (props) => {
  const { data, title, className, helpText, headRows } = props;
  const refElem = useRef(null);
  const { user } = useUsers();


  // useEffect(() => {
  //   if (getHeightBlock) {
  //     if (data.length !== 0 ) {
  //       getHeightBlock(refElem.current.clientHeight)
  //     } else if (loading) {
  //       getHeightBlock(0)
  //     } else {
  //       getHeightBlock(400)
  //     }
  //   }
  // }, [loading]);

  const headRowsFiltered = headRows?.filter(({id}) => id !== "expenses" && id !== "inventory_cost")
  const isNeedsFilter = title === "Marketplace" && user?.id !== 11

  return (
    <Portlet className={className} ref={refElem}>
      <PortletBody fit={true}>
        <div className="page__table__wrap">
          {
            title
            && <OverlayComponent tooltip={helpText}>
              <Typography variant="h5" color="primary" className="MuiTypography-root block-title MuiTypography-h6">{title}</Typography>
            </OverlayComponent>
          }
          <TableComponent
            {...props}
            items={data}
            headRows={isNeedsFilter ?  headRowsFiltered : headRows}
          />
        </div>
      </PortletBody>
    </Portlet>
  )
};

DashboardTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  getHeightBlock: PropTypes.func,
};

export default DashboardTable;
