import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic";
import moment from "moment";
import { onSplitDate } from "../../utils";
import classNames from "clsx";
import Paper from "@material-ui/core/Paper";
import ReportComponent from "../../../components/ReportComponent/ReportComponent";
import DSplashScreen from "../../../components/SplashScreen/DSplashScreen";
import { IMPORTS_TABLE_NAME } from "../../Products/headRowsImports";
import Typography from "@material-ui/core/Typography";
import toast from "../../../components/toast/toast";

export const keysToCheck = ['profit', 'margin', 'refunds', 'roi', 'sales', 'units_sold', 'expenses', 'inventory_cost']

export const typeReports = {
  PRODUCTS: "products",
  AMAZON: "amazon",
  AMAZON_PRODUCT: "amazon-product",
  SALES: "sales"
};

export const styleIcons = {
  Shipped: "shipped",
  Cancelled: "cancelled",
  Unshipped: "unshipped",
  Pending: "pending",
  Refund: "refund",
};

export const TARGET = {
  'product': 'ASIN Note',
  'ppp': 'Repricing Note',
  'order': 'Order Note',
  'refund': 'Refund Note',
  'reconciliation' : "Item Note"
};

export const priceTooltip =  "The Sold Price of a product is not confirmed whilst the order is pending so for now, " +
  "we have based this figure on the last know Sold Price of this product. " +
  " The figure will be updated once we can obtain a confirmed Sold Price from Amazon";

const tooltipVATEdit = "Do you think the VAT Rate is wrong? Please click to edit.";

const tooltipVATValidated = `Our VAT rates have been checked and are 
monitored by Accountants, However if you think this is incorrect please 
tell us by pressing the notify button. We will be notified and we will check.
This is a new feature and our code is still learning, please help by updating your ASIN to the correct VAT Rate.
`;

export const getTooltip = (validated) => validated ? tooltipVATValidated : tooltipVATEdit;

export const fulfilmentIcon = {
  AFN: {
    url: toAbsoluteUrl("/media/d-icons/fba.svg"),
    name: "FBA",
  },
  MFN: {
    url: toAbsoluteUrl("/media/d-icons/fbm.svg"),
    name: "FBM",
  },
  MFN_PRIME: {
    url: toAbsoluteUrl("/media/d-icons/fbm_prime.svg"),
    name: "FBM Prime",
  },
};

export const statusIcon = {
  "£": {
    Pending: {
      url: toAbsoluteUrl("/media/d-icons/status/pending.svg"),
      name: "Pending",
    },
    Cancelled: {
      url: toAbsoluteUrl("/media/d-icons/status/cancelled.svg"),
      name: "Cancelled",
    },
    Paid: {
      url: toAbsoluteUrl("/media/d-icons/status/complete.svg"),
      name: "Paid",
    },
    Complete: {
      url: toAbsoluteUrl("/media/d-icons/status/complete.svg"),
      name: "Complete",
    },
    Refund: {
      url: toAbsoluteUrl("/media/d-icons/status/refunded.svg"),
      name: "Refund",
    },
  },
  "$": {
    Pending: {
      url: toAbsoluteUrl("/media/d-icons/status/pending.svg"),
      name: "Pending",
    },
    Cancelled: {
      url: toAbsoluteUrl("/media/d-icons/status/cancelled.svg"),
      name: "Cancelled",
    },
    Paid: {
      url: toAbsoluteUrl("/media/d-icons/status/complete.svg"),
      name: "Paid",
    },
    Complete: {
      url: toAbsoluteUrl("/media/d-icons/status/complete.svg"),
      name: "Complete",
    },
    Refund: {
      url: toAbsoluteUrl("/media/d-icons/status/refunded.svg"),
      name: "Refund",
    },
  },
  "€": {
    Pending: {
      url: toAbsoluteUrl("/media/d-icons/status/pending.svg"),
      name: "Pending",
    },
    Cancelled: {
      url: toAbsoluteUrl("/media/d-icons/status/cancelled.svg"),
      name: "Cancelled",
    },
    Paid: {
      url: toAbsoluteUrl("/media/d-icons/status/complete.svg"),
      name: "Paid",
    },
    Complete: {
      url: toAbsoluteUrl("/media/d-icons/status/complete.svg"),
      name: "Complete",
    },
    Refund: {
      url: toAbsoluteUrl("/media/d-icons/status/refunded.svg"),
      name: "Refund",
    },
  },
};

export const getOrderStatus = (row, param, currency) => {
  const status = row.order__order_status;
  if (param === 'name'){
    return status
  }
  else if (status) {
    return statusIcon[currency]?.[status]?.[param] || toAbsoluteUrl("/media/logos/new-logo-icon.svg")
  }
};

export const formatDate = (date, location) => {
  let _date = moment(date).format('h:mma DD MMM YY');
  if (location && (location === "USA")) {
    _date = moment(date).format('h:mma MMM DD YY');
  }
  return onSplitDate(_date);
};

export const isToShowBBPIcon = (row, tableName) => row?.from_bbp && tableName !== IMPORTS_TABLE_NAME;

export const tooltipProps = ({ report, type, isLoading, isReturn, placement = "left" }) => {
  const isEmpty = !isLoading && !!report;

  const tooltip = (
    <div className={classNames("order-reports__wrap report__tooltip", {"return": isReturn})}>
      <Paper className={classNames("order-reports__paper", {"order-reports__empty": isEmpty})}>
        {
          isLoading ? (
            <div className="reports__body reports--global order reports--global--loading">
              <div className="d-splash-screen--wrap"><DSplashScreen/></div>
            </div>
          ) : (
            <ReportComponent reportData={report} isReturn={isReturn} order reportType={type} />
          )
        }
      </Paper>
    </div>
  );

  return (
    {
      tooltipStyle: "order-reports__tooltip",
      placement,
      tooltip: !!report ? tooltip : null ,
      isBlur: true,
    }
  );
};

export const Empty = () => <div className="first__dash">
  <img className="table__empty__icon rotating" src={toAbsoluteUrl("/media/logos/new-logo-icon.svg")} alt="logo"/>
  <Typography component="p" variant="h4">
    SKUify is syncing with your Amazon account
  </Typography>
</div>;

export const onSubmitImportWizard = ({rows, showNotifications, addImportsCOG}) => () => {
  const formdata = new FormData();
  const file = rows[0];
  formdata.append("file", file, file.name);

  const onError = (error) => {
    toast.error(error, {duration: 5000}, {isClose: false});
  };
  const onDone = (data) => {
    console.log(data);
  };
  addImportsCOG({
    values: formdata,
    onDone,
    onError,
  })
};
