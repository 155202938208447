import api from '../../crud/notitfications.crud'
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { createSlice } from '@reduxjs/toolkit'

const common = createSlice({
  name: 'notifications',
  initialState: {
    loading: true,
    fetched: false,
    notificationList: {
      details: [],
      total: 0,
      loading: false,
    },
  },
  reducers: {
    stopLoading: (state, actions) => {
      state.notificationList.loading = false;
      return state;
    },
    getNotifications: (state, actions) => {
      state.notificationList.loading = true;
      return state
    },
    getNotificationsSuccess: (state, actions) => {
      const { results, count } = actions.payload;
      state.notificationList.details = results?.length ? results : [];
      state.notificationList.total = count || 0;
      state.notificationList.loading = false;
      return state;
    },

    updateNotifications: (state, actions) => {
      state.notificationList.loading = true;
      return state;
    },
    updateNotificationsSuccess: (state, actions) => {
      state.notificationList.loading = false;
      return state;
    },

    deleteNotifications: (state, actions) => {
      state.notificationList.loading = true;
      return state;
    },
    deleteNotificationsSuccess: (state, actions) => {
      state.notificationList.details = [];
      state.notificationList.total = 0;
      state.notificationList.loading = false;
      return state;
    },
  }
});

export const actions = common.actions;
export const reducer = common.reducer;

export function* saga() {
  yield takeLatest(actions.getNotifications, function* getNotificationsSaga({payload: {values, onDone, onError} }) {

    // const data = notifications_data;
    // yield put(actions.getNotificationsSuccess({ data }));
    try {
      const { data, ...props } = yield call(api.getNotifications, values);
      if (data) {
        yield put(actions.getNotificationsSuccess({ ...data }));
        onDone && onDone(data);
      } else {
        onError && onError(props);
        yield put(actions.stopLoading());
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError && onError(err);
      console.log(err)
    }
  });

  yield takeLatest(actions.updateNotifications, function* updateNotificationsSaga({payload}) {
    const {id, values} = payload;
    try {
      const res = yield call(api.updateNotifications, {id, values});
      if (!res) {
        yield put(actions.stopLoading());
        throw new Error("Not found")
      }
      yield put(actions.updateNotificationsSuccess());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteNotifications, function* deleteNotificationsSaga({payload}) {
    const {onDone, onError} = payload;
    try {
      const res = yield call(api.deleteNotifications);
      if (!res) {
        yield put(actions.stopLoading());
        throw new Error("Not found")
      }
      yield put(actions.deleteNotificationsSuccess());
      if (onDone)
        onDone();
    } catch (err) {
      onError(err);
      yield put(actions.stopLoading());
      console.log(err);
    }
  });
}
