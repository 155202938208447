import React from "react";
import {Grid, Paper, Typography, IconButton, Button, Chip} from "@material-ui/core";
import classNames from "clsx";
import * as PropTypes from "prop-types";
import ReportComponent from "../../components/ReportComponent/ReportComponent";
import Form from "react-bootstrap/Form";
import {ReactComponent as CloseIcon} from "../../../../_metronic/layout/assets/layout-svg-icons/close.svg";

import _ from 'lodash';
import {caseLogs, caseLogsFields, formatRowData} from "./utils";
import {currencySymbols} from "../utils";
import useUsers from "../../../hooks/users.hook";

const CollapsedRow = ({showExtraRow, row, handleChange, handlerRaiseCase, handleChaiseCase}) => {
  let {id, collapse, countdown_pending_reimbursements: pending} = row;
  const {user} = useUsers({fetch: false});

  if (id) {
    const currency = currencySymbols[user.currency];
    let reconciliationData = !_.isEmpty(collapse[0].data) && collapse[0].data.map(item => ({...item, noCurrency: true}));
    let inventoryData = !_.isEmpty(collapse[2].data) && collapse[2].data.map(item => ({...item, noCurrency: true}));

    const days = pending && (pending < 2 ? `${pending} day` : `${pending} days`);

    let caseLogsData = collapse[1].data;

    return (
      <Paper elevation={0} className="extra-row">
        <IconButton className="extra-row__close" onClick={(e) => handleChange(e, row)}>
          <CloseIcon fontSize="medium" htmlColor="var(--color-on-surface-1)"/>
        </IconButton>
        <Grid container spacing={4}>
          <Grid item xs={5}>
            <Grid item xs={12}>
              <div className="reports__header">
                <Typography variant="h5" color="primary" className="reports__title">{collapse[2].name}</Typography>
              </div>
              {!_.isEmpty(collapse[2].data) && (
                <ReportComponent
                  reportData={[{...collapse[2], data: inventoryData, name: ""}]}
                  isReturn
                  extraStyle="reconciliation__collapse"
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item xs={12}>
              <div className="reports__header">
                <Typography variant="h5" color="primary" className="reports__title">{collapse[0].name}</Typography>
                {!!pending && <Chip className="reports__chip" color="primary" label={days}/>}
                <Button
                  variant="contained"
                  color="primary"
                  className="reports__btn"
                  onClick={handlerRaiseCase}
                  // todo add condition
                  disabled={!!pending}
                >
                  Raise a case
                </Button>
              </div>
              <ReportComponent
                reportData={[{...collapse[0], data: reconciliationData, name: ""}]}
                isReturn
                extraStyle="reconciliation__collapse"
              />
            </Grid>
            <Grid item xs={12}>
            {/*<Grid item xs={12}>*/}
            {/*  <div className="reports__header">*/}
            {/*    <Typography variant="h5" color="primary" className="reports__title">{collapse[1].name}</Typography>*/}
            {/*  </div>*/}
            {/*  <ReportComponent reportData={[{...collapse[1], name: ""}]} isReturn extraStyle="vertical-align" />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12}>*/}
            <div className="reports__header">
              <Typography variant="h5" color="primary" className="reports__title">{collapse[1].name}</Typography>
              <Button
                variant="contained"
                color="primary"
                // endIcon={<EmailIcon/>}
                className="reports__btn"
                onClick={handleChaiseCase}
              >
                Chase a case
              </Button>
            </div>
            <div className="reports__body reports--global reports--global--color vertical-align">
              <div className="reports-item">
                {!!caseLogsData.length && caseLogsData.map((item, indexRow) => {
                  const displayData = Object.entries(_.pick(item, caseLogs));
                  return (
                    <div key={indexRow}>
                      {!indexRow && (
                        <div className="reports__row">
                          {displayData.map((item, index) => {
                              const FIELD_NAME = 0;
                              const name = `${item[FIELD_NAME]}--${indexRow}--${index}`;
                              let label = caseLogsFields.find(({name}) => item[FIELD_NAME] === name)?.label;

                              return (
                                <Form.Group
                                  key={name}
                                  controlId={name}
                                  className={classNames(`form-group--${name} last-block`)}
                                >
                                  <Typography variant="h5" color="primary" className="header__item">
                                    {label}
                                  </Typography>
                                </Form.Group>
                              )
                            })
                          }
                        </div>
                      )}

                      <div className="reports__row">
                        {displayData.map((item, index) => {
                          const FIELD_NAME = 0, FIELD_VALUE = 1;
                          const name = `${item[FIELD_NAME]}--${indexRow}--${index}`;

                          return (
                            <Form.Group
                              key={name}
                              controlId={name}
                              className={classNames(`form-group--${name} last-block`)}
                            >
                              <Typography variant='body1' className="value__item">
                                {formatRowData({name: item[FIELD_NAME], field: item[FIELD_VALUE], currency})}
                              </Typography>
                            </Form.Group>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {/*</Grid>*/}
          </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
  return null;
};

CollapsedRow.propTypes = {
  displayExtraRow: PropTypes.string,
  row: PropTypes.object,
  handleChange: PropTypes.func,
  handlerRaiseCase: PropTypes.func,
};

export default CollapsedRow;