import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as companyDuck from "../../../store/ducks/company.duck";
import * as commonDuck from "../../../store/ducks/common.duck";
import "./style-category.scss";
import TableComponent from "../../components/TableComponent/TableComponent";
import TableToolbarComponent from "../../components/TableComponent/TableToolbarComponent";
import withConfirmHook from "../../../hooks/withConfirm.hook";
import * as CompanySelectors from '../../../selectors/company.selectors';
import { getTaxes } from "../../../selectors/common.selectors";
import { useCheckboxStorage, useModal } from "../../../hooks/modal.hook";
import * as PropTypes from "prop-types";
import { Redirect, useHistory } from "react-router-dom";
import * as bank from "../../../store/ducks/bunk.duck";
import EditAddBankingModal from "../Transaction/EnterTransactionsBanking/EditAddBankingModal";
import TransactionModal, { valuesTitle } from "../Transaction/TransactionModal/TransactionModal";
import { getCurrentCompanyId } from "../../../crud/utils";
import Wrapper from "../../components/Wrapper";
import CategoryEditAddModal from "./CategoryEditAddModal";
import { goToNewAdjustmentPage } from "./utils";
import { useNotification} from "../../../hooks/notification.hook";
import { cloneCategory, datePicker, headRows } from "./categotiesElements";
import { headRowsModal } from "../Transaction/EnterTransactionsBanking/headRowsModal";
import { periodOfTime } from "../Reports/ReportsGeneral";
import moment from 'moment'
import AddIcon from '@material-ui/icons/Add';
import {filterItems, momentFormatOptions} from "../utils";
import useTableParams from "../../../hooks/useTableParams";
import useCategories from "../../../hooks/categories.hook";
import debounce from "lodash/debounce";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import useCompany from "../../../hooks/company.hook";

const title = "All Categories";
const formatDate = (date) => moment(date).format(momentFormatOptions.display);

const WrapContainer = ({ children, step }) => step ? children : <Wrapper>{children}</Wrapper>;

function CategoryList(props) {
  const {
    taxes,
    getTaxes,
    confirm,
    deleteBank,
    updateBank,
    step
  } = props;

  const { company } = useCompany();

  const [currentRow, setCurrentRow] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [query, setQuery] = useState(null);
  const [errorDate, setErrorDate] = useState(false);
  const [isModalShow, toggleModal] = useModal();
  const [filterCheckboxes, setFilterCheckboxes] = useCheckboxStorage("categories_filter", filterItems);
  const [isEditBankModalShow, toggleEditBankModal] = useModal();
  const [isTransactionModalShow, toggleTransactionModal] = useModal();
  const titleTransModal = currentRow && currentRow.category_type ? valuesTitle.CATEGORY : valuesTitle.BANKING;
  const [successNotification, setIsShowNotification] = useNotification(false, "success", "Updated");
  const [activeColumns, setActiveColumns] = useCheckboxStorage("categories", headRows(step));
  const { categories: { details: categories, loading }, getCategories, deleteCategory, } = useCategories();
  const { setParams } = useTableParams(getCategories, { start_date: "", end_date: "" });

  const isCompany = !!getCurrentCompanyId() !== false;
  useEffect(() => {
    if (isCompany) {
      getTaxes();
    }
  }, []);

  const startPicker = startDate ? startDate : company && company.prog_date_start;
  const endPicker = endDate ? endDate : company && company.prog_date_end;

  const decoratorDebounce = (param, ms) => debounce((q) => {
    let params = { start_date: startDate, end_date: endDate, q: query };
    if (param === "q") {
      setQuery(q);
      params.q = q;
    } else if (param === "date") {
      params = { q, ...params }
    }
    setParams(params);
  }, ms || 500);

  const onCreate = () => toggleModal();
  let history = useHistory();
  const onCreateAdjustment = () => goToNewAdjustmentPage(history);
  const onExport = () => console.log("onExport");
  const getStartDate = (name, date) => setStartDate(date);
  const getEndDate = (name, date) => setEndDate(date);
  const onRefresh = () => {
    if (startPicker && endPicker && startPicker > endPicker) {
      setErrorDate(true);
    } else {
      setErrorDate(false);
      decoratorDebounce("date")({ start_date: startDate, end_date: endDate });
    }
  };

  const onEdit = (row) => () => {
    const rowCopy = {...row};
    if (row.category_type) {
      if (row.doddle_group) {
        rowCopy.doddle_group = {
          label: row.doddle_group.name,
          value: row.doddle_group.id,
        }
      }
      toggleModal();
    } else {
      toggleEditBankModal();
    }
    setCurrentRow(rowCopy);
  };

  const onDelete = (row) => (
    confirm(() => {
        row.category_type ? deleteCategory({ id: row.id }) : deleteBank({ id: row.id })
      },
      {description: 'This action is permanent!'})
  );
  const reset = () => setCurrentRow(null);
  const onClose = () => {
    reset();
    toggleModal();
    getCategories();
  };

  const onSuccess = () => {
    setIsShowNotification(true);
    onClose()
  };

  const onCloseEditBank = () => {
    toggleEditBankModal();
    reset();
  };

  const onSuccessBank = () => {
    setIsShowNotification(true);
    onCloseEditBank()
  };

  const onNameClick = (row) => () => {
    const rowCopy = {...row};
    if (step && !row.entry_method) {
      toggleModal();
    } else {
      toggleTransactionModal();
    }
    setCurrentRow(rowCopy);
  };

  // clone categories for sorting by nominal code
  const cloneCategories = cloneCategory(categories);

  const items = () => {
    const active = filterCheckboxes.find(checkbox => checkbox.id === "inactive");
    const balance = filterCheckboxes.find(checkbox => checkbox.id === "balance");
    if (active.isActive === false && balance.isActive === false) {
      //use active categories with balance
      console.log("active categories with balance");
      return cloneCategories;
    }
    if (active.isActive === false) {
      return cloneCategories.filter(category => category.status === true);
    }
    if (balance.isActive === false) {
      // use all categories with balance
      console.log("all categories with balance");
      return cloneCategories;
    } else {
      return cloneCategories;
    }
  };



  if (!!getCurrentCompanyId() === false) {
    history.push('/companies');

  }

  const propsPicker = { onRefresh, errorDate, getStartDate, startDate: startPicker, getEndDate, endDate: endPicker };

  let subtitleTransModal;
  if (errorDate) {
    subtitleTransModal = periodOfTime();
  } else if (currentRow && currentRow.nominal_code < 400 || titleTransModal === valuesTitle.BANKING) {
    subtitleTransModal = periodOfTime(undefined, formatDate(endPicker));
  } else {
    subtitleTransModal = periodOfTime(formatDate(startPicker), formatDate(endPicker));
  }

  const addMenu = {
    array: [
      { func: onCreateAdjustment, content: <><AddIcon/>New Adjustment</> },
      { func: onCreate, content: <><AddIcon/>New Category</> },
    ]
  };
  const addMenuStep = {
    array: [
      { func: onCreate, content: <><AddIcon/>New Category</> },
      { func: toggleEditBankModal, content: <><AddIcon/>Add Bank Account</>}
    ]
  };

  const commonToolbarProps = { title,
    actionsHeader: {
      onRefresh,
      onExport ,
      addMenu,
      filterMenu: { array: filterCheckboxes, type: "filter", setFunc: setFilterCheckboxes },
      viewColumnMenu: { array: activeColumns, type: "filter", setFunc: setActiveColumns, disabled: true },
    },
  };
  const defToolbarProps = { bottomHeader: datePicker(propsPicker), setSearchQuery: decoratorDebounce("q"), onRefresh, ...commonToolbarProps };
  const stepsToolbarProps = { isHideSearch: true, actionsHeader: { addMenu: addMenuStep } };

  return (
    <WrapContainer step={step}>
      {step ? <TableToolbarComponent {...stepsToolbarProps} /> : <TableToolbarComponent {...defToolbarProps} />}
       <div className="d-splash-screen--wrap">
         {loading && <DSplashScreen />}
         <TableComponent
           className="categories"
           headRows={activeColumns.filter(column => column.isActive)}
           items={items()}
           rowProps={{ onDelete, onEdit, onNameClick }}
           loading={loading}
         />
       </div>
      {successNotification}
      <CategoryEditAddModal
        reset={reset}
        onClose={onClose}
        showModal={isModalShow}
        initValues={currentRow}
        taxes={taxes}
        onSuccess={onSuccess}
        step={step}
      />
      {currentRow && (
        <TransactionModal
          data={currentRow}
          show={isTransactionModalShow}
          onClose={toggleTransactionModal}
          valueTab={titleTransModal}
          headRowsModal={headRowsModal}
          periodOfTime={subtitleTransModal}
          reset={reset}
        />
      )}
      <EditAddBankingModal
        reset={reset}
        updateBank={updateBank}
        show={isEditBankModalShow}
        onClose={onCloseEditBank}
        initValues={currentRow}
        onSuccess={onSuccessBank}
        categories={categories}
      />
    </WrapContainer>
  );
}

CategoryList.propTypes = {
  updateBank: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  taxes: PropTypes.array.isRequired,
  step: PropTypes.number,
  getTaxes: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    taxes: getTaxes(state),
  }
};
const mapDispatchToProps = {
  getTaxes: commonDuck.actions.getTaxes,
  updateBank: bank.actions.updateBank,
  deleteBank: bank.actions.deleteBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withConfirmHook(CategoryList))