import React from 'react';
import { Helmet } from 'react-helmet';

const BodyScript = () => {
  const affaimEndpoint = `${process.env.REACT_APP_API_AFFAIM_ENDPOINT}`;
  const affaimBrandID = process.env.REACT_APP_API_AFFAIM_BRAND_ID;

  if (!affaimEndpoint) {
    return null
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`
        (() => {
          "use strict";
          var e = {};
          function r(e, r, t) {
            var s, n, o, a, l;
            t ? ((o = new Date).setTime(o.getTime() + 24 * t * 60 * 60 * 1e3), a = "; expires=" + o.toGMTString()) : a = "", 1 === (l = location.host).split(".").length ? document.cookie = e + "=" + r + a + "; path=/" : ((n = l.split(".")).shift(), s = "." + n.join("."), document.cookie = e + "=" + r + a + "; path=/; domain=" + s, null != i(e) && i(e) == r || (s = "." + l, document.cookie = e + "=" + r + a + "; path=/; domain=" + s))
          }
          function i(e) {
            for (var r = e + "=", i = document.cookie.split(";"), t = 0; t < i.length; t++) {
              for (var s = i[t]; " " == s.charAt(0);) s = s.substring(1);
              if (0 == s.indexOf(r)) return s.substring(r.length, s.length)
            }
            return ""
          }
          function t(e, r, i) {
            return (r = function (e) {
              var r = function (e, r) {
                if ("object" != typeof e || null === e) return e;
                var i = e[Symbol.toPrimitive];
                if (void 0 !== i) {
                  var t = i.call(e, "string");
                  if ("object" != typeof t) return t;
                  throw new TypeError("@@toPrimitive must return a primitive value.")
                }
                return String(e)
              }(e);
              return "symbol" == typeof r ? r : String(r)
            }(r)) in e ? Object.defineProperty(e, r, {
              value: i,
              enumerable: !0,
              configurable: !0,
              writable: !0
            }) : e[r] = i, e
          }
          e.g = function () {
            if ("object" == typeof globalThis) return globalThis;
            try {
              return this || new Function("return this")()
            } catch (e) {
              if ("object" == typeof window) return window
            }
          }();
          class s {
            constructor() {
              t(this, "params", [{ type: "AffiliateId", paramName: "affiliateId" }, { type: "IntegrationType", paramName: "ads" }, { type: "LinkKey", paramName: "key" }]), t(this, "model", {}), t(this, "cookieDuration", 1)
            }
            processQueryString() {
              const e = window.location.search, r = new URLSearchParams(e);
              this.cookieDuration = r.get("exp") || 1, this.params.every((e => !r.has(e.paramName) || (this.cookieClear(), this.saveParamsToCookie(r), !1))), this.getValuesFromCookie()
            }
            saveParamsToCookie(e) {
              this.params.forEach((i => {
                const t = e.get(i.paramName);
                t && r(i.paramName, t, this.cookieDuration)
              }))
            }
            getValuesFromCookie() {
              this.model = {}, this.params.forEach((e => {
                let r = i(e.paramName);
                r && (this.model[e.type] = this.isNumeric(r) ? +r : r)
              }))
            }
            cookieClear() {
              this.params.forEach((e => {
                var r;
                i(r = e.paramName) && (document.cookie = r + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT")
              }))
            }
            isNumeric(e) {
              return !isNaN(e)
            }
          }
          function n(e, r, i) {
            return (r = function (e) {
              var r = function (e, r) {
                if ("object" != typeof e || null === e) return e;
                var i = e[Symbol.toPrimitive];
                if (void 0 !== i) {
                  var t = i.call(e, "string");
                  if ("object" != typeof t) return t;
                  throw new TypeError("@@toPrimitive must return a primitive value.")
                }
                return String(e)
              }(e);
              return "symbol" == typeof r ? r : String(r)
            }(r)) in e ? Object.defineProperty(e, r, {
              value: i,
              enumerable: !0,
              configurable: !0,
              writable: !0
            }) : e[r] = i, e
          }
          e.g.affaim = new class {
            constructor() {
              n(this, "host", void 0), n(this, "productCode", void 0), n(this, "sessionId", void 0), n(this, "sessionInitPromise", void 0), n(this, "queryStringHandler", void 0), n(this, "isDevelopment", void 0), this.host = "${affaimEndpoint}", this.productCode = null, this.sessionId = null, this.sessionInitPromise = Promise.resolve(null), this.queryStringHandler = new s, this.isDevelopment = !0
            }
            init(e) {
              let r = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
              e || this.handleError("Brand code is invalid"), this.productCode = e, this.isDevelopment = r, this.queryStringHandler.processQueryString(), this.initSession()
            }
            sendClick() {
              this.sessionInitPromise.then((e => {
                this.sessionId || this.handleError("Session was not initialize");
                let r = { ...this.queryStringHandler.model, sessionId: this.sessionId, productCode: this.productCode, referrerUrl: document.referrer, landingPageUrl: this.getCurrentUrl() };
                return this.sendData("api/Click/Create", r)
              })).catch((e => {
                this.handleError(e.message)
              }))
            }
            sendCustomer(e) {
              this.sessionInitPromise.then((r => {
                if (!this.sessionId) throw new Error("Session was not initialize");
                let i = { sessionId: this.sessionId, email: e.email, fullName: e.fullname };
                return this.sendData("api/Customer/Create", i)
              })).catch((e => {
                this.handleError(e.message)
              }))
            }
            sendPurchase(e) {
              this.sessionInitPromise.then((r => {
                if (!this.sessionId) throw new Error("Session was not initialize");
                let i = { ...this.queryStringHandler.model, sessionId: this.sessionId, currency: e.currency, items: e.items, email: e.email, fullName: e.fullname, externalOrderId: e.externalOrderId, referrerUrl: document.referrer, landingPageUrl: this.getCurrentUrl() };
                return this.sendData("api/Purchase/Create", i)
              })).catch((e => {
                this.handleError(e.message)
              }))
            }
            sendStripeSubscription(e) {
              this.sessionInitPromise.then((r => {
                if (!this.sessionId) throw new Error("Session was not initialize");
                let i = { ...this.queryStringHandler.model, sessionId: this.sessionId, stripeSubscriptionId: e.subscriptionId, externalOrderId: e.externalOrderId, affaimOfferId: e.affaimOfferId, currency: e.currency, price: e.price, email: e.email, fullName: e.fullName, referrerUrl: document.referrer, landingPageUrl: this.getCurrentUrl() };
                return this.sendData("api/Purchase/StripeSubscription", i)
              })).catch((e => {
                this.handleError(e.message)
              }))
            }
            sendPaypalSubscription(e) {
              this.sessionInitPromise.then((r => {
                if (!this.sessionId) throw new Error("Session was not initialize");
                let i = { ...this.queryStringHandler.model, sessionId: this.sessionId, paypalSubscriptionId: e.subscriptionId, externalOrderId: e.externalOrderId, affaimOfferId: e.affaimOfferId, currency: e.currency, price: e.price, email: e.email, fullName: e.fullName, referrerUrl: document.referrer, landingPageUrl: this.getCurrentUrl() };
                return this.sendData("api/Purchase/PayPalSubscription", i)
              })).catch((e => {
                this.handleError(e.message)
              }))
            }
            initSession() {
              const e = "affaimSessionId";
              this.sessionId = i(e), this.sessionId && "null" !== this.sessionId || (this.sessionInitPromise = this.sendData("api/Session/Create", {}).then((i => { r(e, i.id, 365), this.sessionId = i.id })))
            }
            getCurrentUrl() {
              return window.location.href.split("?")[0]
            }
            sendData(e, r) {
              let i = this.host + e, t = { method: "POST", mode: "cors", headers: { "Content-Type": "application/json;charset=utf-8" }, keepalive: !0 };
              return r && (t.body = JSON.stringify(r)), fetch(i, t).then((r => {
                if (!r.ok) throw new Error(\`Bad request: {route: '\${e}', body: \${t.body}\`);
                return r
              })).then((e => e.json()))
            }
            handleError(e) {
              if (this.isDevelopment) throw new Error(e);
              this.sendError(e)
            }
            sendError(e) {
              var r = { productCode: this.productCode, landingPageUrl: this.getCurrentUrl(), message: e };
              this.sendData("api/Logger/Error", r).then((e => { })).catch((e => { console.log(e.message) }))
            }
          }
        })();
      `}
      </script>
      <script type="text/javascript">
        {
          `(() => {
              "use strict";
              affaim.init("${affaimBrandID}");
              affaim.sendClick();
            })();`
        }
      </script>

    </Helmet>
  );
};

export default BodyScript;
