import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {finishSetup, getUserByToken} from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";
import * as settingsDuck from "../../store/ducks/settings.duck";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SaveSettings: "SaveSettings auth api",
  SaveSettingsDone: "SaveSettingsDone auth api",
  FinishSetup: "FinishSetup",
  FinishSetupDone: "[FinishSetupDone] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "demo2-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, refreshToken } = action.payload;

        return { refreshToken, authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        localStorage.removeItem("login");
        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }
      case actionTypes.SaveSettingsDone: {
        return { ...state };
      }

      case actionTypes.FinishSetupDone: {
        return { ...state };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, refreshToken) => ({ type: actionTypes.Login, payload: { authToken, refreshToken } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  saveSettings: (data) => ({ type: actionTypes.SaveSettings, payload: data }),
  saveSettingsDone: (data) => ({ type: actionTypes.SaveSettingsDone, payload: data }),
  finishSetup: (data) => ({ type: actionTypes.FinishSetup, payload: data }),
  finishSetupDone: (data) => ({ type: actionTypes.FinishSetupDone, payload: data }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
    yield put(settingsDuck.actions.getAccountSettingsSuccess({ accountSettings: user }))
  });

  yield takeLatest(actionTypes.SaveSettings, function* saveSettingsSaga({ payload: { values, onDone, onError } }) {
    try {
      yield put(actions.requestUser());
      if (onDone) {
        onDone();
      }
    } catch (e) {
      onError(e);
    }
  });

  yield takeLatest(actionTypes.FinishSetup, function* finishSetupSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield finishSetup(values);
      if (data) {
        yield put(actions.finishSetupDone(data));

        if (onDone) {
          onDone(data);
        }
      } else {
        onError(props);
      }
    } catch (e) {
      onError(e);
    }
  });
}
