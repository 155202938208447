import React, {useState} from "react";
import Popover from '@material-ui/core/Popover';
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state';
import Button from "@material-ui/core/Button";
import productApi from "../../../crud/product.crud";
import OverlayComponent from "../../components/OverlayComponent";
import DSplashScreen from "../../components/SplashScreen/DSplashScreen";
import {FormControlLabel, Switch} from "@material-ui/core";
import { ReactComponent as ExportIcon } from "../../../media/icons/export.svg";
import {ReactComponent as ImportIcon} from "../../../media/icons/import.svg";

export const smallSpinner = <div className="d-splash-screen--wrap button-spinner"><DSplashScreen color="white"/></div>;

const ExportCOG = props => {
  const {action, extraProps} = props;
  const [loading, setLoading] = useState('');
  const [checkedStock, setCheckedStock] = useState(false);
  const [checkedInventory, setCheckedInventory] = useState(false);

  const handleChangeStock = (event) => {
    setCheckedStock(event.target.checked);
  };
  const handleChangeInventory = (event) => {
    setCheckedInventory(event.target.checked);
  };

  const downloadData = (url, format) => {
    setLoading(format);
    const title = extraProps?.downloadFileName || "SKUify Inventory Export";
    const downloadFileName = `${title}.${format}`;
    productApi.getExportProducts(url, downloadFileName)
      .then(response => {
          setLoading('');
        }
      );
  };

  const getExportUrl = (type) => {
    if (extraProps) {
      return `${extraProps.url}/export/?doc_format=${type}&`
    } else {
      const stock = checkedStock ? "stock_or_inactive=true&" : "";
      const inventory = checkedInventory ? "no_inventory_cost=true&" : "";
      return `/product/export/?doc_format=${type}&${stock}${inventory}`
    }
  };

  const getCSV = () => {
    downloadData(getExportUrl('csv'), 'csv');
  };

  const getExcel = () => {
    downloadData(getExportUrl('xlsx'), 'xlsx');
  };

  const getGoogle = () => {
    downloadData(getExportUrl('spreadsheet'), 'gsheet');
  };

  return (
    <PopupState variant="popper" popupId="export-cog__popup-popover">
      {popupState => (
        <div>
          <OverlayComponent
            tooltip="Click here to export your inventory into a CSV file for easy editing of your Cost of Goods"
          >
            {action
              ? action(bindTrigger(popupState))
              : <Button variant="contained" size="medium" className="header-button" {...bindTrigger(popupState)}>
                <ImportIcon className="icon-component import-icon rotate-180" />
                {/*<ExportIcon className="icon-component export-icon" />*/}
                Export
              </Button>
            }
          </OverlayComponent>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="wrap-button">
             {!extraProps && (
               <>
                 <FormControlLabel
                   labelPlacement="start"
                   control={
                     <Switch
                       className="d-switch"
                       checked={checkedStock}
                       onChange={handleChangeStock}
                       inputProps={{'aria-label': `checkbox`}}
                     />
                   }
                   label="Out of Stock or Inactive"
                 />
                 <FormControlLabel
                   labelPlacement="start"
                   control={
                     <Switch
                       className="d-switch"
                       checked={checkedInventory}
                       onChange={handleChangeInventory}
                       inputProps={{'aria-label': `checkbox`}}
                     />
                   }
                   label="No inventory cost only"
                 />
               </>
             )}
              <div className="wrap-export-btn">
                <Button
                  variant="contained"
                  size="medium"
                  onClick={getCSV}
                  className="export-btn export-btn--csv"
                >
                  {loading === 'csv' ? smallSpinner : "CSV"}
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  className="export-btn export-btn--csv"
                  onClick={getExcel}
                >
                  {loading === 'xlsx' ? smallSpinner : "XLSX"}
                </Button>
                {/*<Button variant="contained" size="medium" color="secondary" onClick={getGoogle}>Googlesheets</Button>*/}
              </div>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  )
};

export default ExportCOG;