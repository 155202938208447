import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import api from "../../crud/user.crud";
import {createSlice} from "@reduxjs/toolkit";

const company = createSlice({
    name: 'user',
    initialState: {
      loading: true,
      users: [],
    },
    reducers: {
      stopLoading: (state, actions) => {
        state.loading = false;
        return state;
      },
      getUsers: (state, actions) => {
        state.loading = true;
        return state;
      },
      getUsersSuccess: (state, actions) => {
        const {users} = actions.payload;
        state.users = users;
        state.loading = false;
        return state;
      },
      addUser: (state, actions) => {
        state.loading = true;
        return state;
      },
      addUserSuccess: (state, actions) => {
        const {user} = actions.payload;
        state.users = [...state.users, user];
        state.loading = false;
        return state;
      },
      deleteUser: (state, actions) => {
        state.loading = true;
        return state;
      },
      deleteUserSuccess: (state, actions) => {
        const {id} = actions.payload;
        state.users = state.users.filter((user) => (user.id !== id));
        state.loading = false;
        return state;
      },
      updateUser: (state, actions) => {
        state.loading = true;
        return state;
      },
      updateUserSuccess: (state, actions) => {
        state.users = state.users.map((user) => {
          if (user.id === actions.payload.id) {
            return actions.payload.user;
          }
          return user
        });
        state.loading = false;
        return state;
      },
    },
  }
);

export const actions = company.actions;
export const reducer = company.reducer;

export function* saga() {
  yield takeLatest(actions.getUsers, function* getUserSaga() {
    try {
      const { data } = yield call(api.getUsers);
      data
        ? yield put(actions.getUsersSuccess({ users: data }))
        : yield put(actions.stopLoading());
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.addUser, function* addUserSaga({ payload: { values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.addUser, values);
      if (data) {
        yield put(actions.addUserSuccess({ user: data }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });

  yield takeLatest(actions.deleteUser, function* deleteUserSaga({ payload: { id, onDone } }) {
    try {
      yield put(actions.deleteUserSuccess({ id }));
      if (onDone)
        onDone();
    } catch (err) {
      yield put(actions.stopLoading());
      console.log(err);
    }
  });

  yield takeLatest(actions.updateUser, function* updateUserSaga({ payload: { id, values, onDone, onError } }) {
    try {
      const { data, ...props } = yield call(api.updateUser, id, values);
      if (data) {
        yield put(actions.updateUserSuccess({ user: data, id }));
        onDone();
      } else {
        yield put(actions.stopLoading());
        onError(props);
      }
    } catch (err) {
      yield put(actions.stopLoading());
      onError(err);
      console.log(err);
    }
  });
}
