import React from "react";
import toast from "../../../components/toast/toast";
import {currentDate, currentDay, floatNumber, formatDisplayDate, nextDay, prevDay} from "../../utils";
import HistoryIcon from '@material-ui/icons/History';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import { TabsLabel } from "./components";

export const TYPES = {
  vat: "vat",
  schemes: "schemes",
  roi: "roi",
  wl: "wl",
};

const getTooltip = (val, name) => {
  return val.roi_method && val.roi_method.help.find(item => item.name === name)
};

export const onHoverLabel = (val) => {
  // if no-registered for VAT
  if (!val.registered) {
    return getTooltip(val, "Non VAT Registered")
  }
  // if registered for VAT  and VAT scheme "Flat Rate - Invoice Based" or "Flat Rate - Cash Accounting"
  else if (val.vat_scheme && (
    (val.vat_scheme.value && (val.vat_scheme.value === "FR-IB" || val.vat_scheme.value === "FR-CA")) ||
    (val.vat_scheme === "FR-IB" || val.vat_scheme === "FR-CA")
  )) {
    return getTooltip(val, "Flat Rate Scheme")
  } else if (val.registered) {
    return getTooltip(val, "VAT Registered")
  }
};

export const vatSettingsType = ({ qty }) => {
  const tabs = {
    current: <TabsLabel name="Current" icon={(props) => <AccessTimeRoundedIcon {...props} />} />
  };
  return (
    qty > 1
      ? {...tabs, historic: <TabsLabel name="Historic" icon={(props) => <HistoryIcon {...props} />} />}
      : tabs
  )
};

// const tabsSchemes = {
//   current: <TabsLabel name="Current" icon={(props) => <AccessTimeRoundedIcon {...props} />} />,
//   historic: <TabsLabel name="Historic" icon={(props) => <HistoryIcon {...props} />} />,
//   new: <TabsLabel name="Add New" icon={(props) => <AddCircleOutlineRoundedIcon {...props} />} />,
// };

export const schemesType = (parentTabName, qtySchemes) => {
  const base = {
    historic: <TabsLabel name="Historic" icon={(props) => <HistoryIcon {...props} />} />,
  };
  return (
    parentTabName === "historic"
      ? base
      : qtySchemes > 1
        ? {
          current: <TabsLabel name="Current" icon={(props) => <AccessTimeRoundedIcon {...props} />} />,
          ...base,
          new: <TabsLabel name="Add New" icon={(props) => <AddCircleOutlineRoundedIcon {...props} />} />,
        }
        : {
          current: <TabsLabel name="Current" icon={(props) => <AccessTimeRoundedIcon {...props} />} />,
          new: <TabsLabel name="Add New" icon={(props) => <AddCircleOutlineRoundedIcon {...props} />} />,
        }
  )
};

export const initVAT = {
  scheme_registration_date: null,
  flat_rate_percentage: null,
  report_vat: null,
  scheme_name: null,
};

export const onSubmit = (props) => (values, { setSubmitting }) => {
  const {type, successNote} = props;
  const data = {...values};

  const onDone = () => {
    setSubmitting(false);
    toast.success(successNote);
  };

  const onError = (error) => {
    setSubmitting(false);
    toast.error(error);
  };

  if (type === TYPES.vat) {
    const {
      currentTab,
      handleDialog,
      initData,
    } = props;
    const {updateVATSetting } = props;
    const dataName = `schemes_${currentTab}`;

    const currentVAT = values?.global_vat_current[0];
    const formatFlatRate = floatNumber(values[dataName][0].flat_rate_percentage);
    const updateScheme = [{ ...values[dataName][0], flat_rate_percentage: formatFlatRate }];
    const allData = { ...values, ...currentVAT, [dataName]: updateScheme };

    const data = { ...allData };

    if (allData.is_vat_registered) {
      data.vat_schemes = currentTab === "current"
        ? [...allData.schemes_historic, ...formatData(allData.schemes_current)]
        : [...allData.vat_schemes, ...formatData(allData.schemes_new)];
    }

    deleteFields.forEach(name => delete data[name]);

    if (!!textAlert(values?.global_vat_current?.[0], initData)) {
      handleDialog({ onDone, onError, values: data });
    } else {
      updateVATSetting({ onDone, onError, values: data })
    }
  }

  if (type === TYPES.roi) {
    const {updateROISetting, handleDialog, initData} = props;
    // if (!!textAlertROI({roi_method:values?.roi_method, initROI: initData.roi_method})) {
    //   handleDialog({method: values.roi_method, onDone, onError});
    // } else {
      updateROISetting({method: values.roi_method, onDone, onError});
    // }
  }

  if (type === TYPES.wl) {
    const {updateAccountSetting, userId} = props;

    const profitData = {
      profit_winner: floatNumber(data.profit_winner),
      profit_looser: floatNumber(data.profit_looser),
    };

    updateAccountSetting({ id: userId, values: profitData, onError, onDone })
  }
};

export const formatData = (data) => data.map(item =>
  Object.fromEntries(
    Object.entries(item).map(([key, val]) => {
      if (val && typeof val === "object") {
        return [key, val.value]
      }
      return [key, val];
    })
  )
);

const alertVATUpdateOptions = {
  nonToReg:
    (startDate, nextDate) =>
      `We will lock all sales up to ${startDate} as non VAT registered 
      and calculate all sales from this ${nextDate} as per your new VAT scheme.`,

  regToNon:
    (endDate, nextDate) =>
      `We will lock all sales up to ${endDate} as per your existing VAT scheme 
      and calculate all sales from this ${nextDate} as per your new non VAT registered status.`,

  nonToExempt:
    (startDate, nextDate) =>
      `We will lock all sales up to ${startDate} as non VAT registered 
      and calculate all sales from this ${nextDate} as per your new VAT fees exempt status.`,

  regToExempt:
    (prevDate, nextDate) =>
      `We will lock all sales up to ${prevDate} as per your existing VAT scheme 
      and calculate all sales from this ${nextDate} as per your new VAT fees exempt status.`,

  exemptToReg:
    (prevDate, nextDate) =>
      `We will lock all sales up to ${prevDate} as per your existing VAT fees exempt status 
      and calculate all sales from this ${nextDate} as per your new VAT scheme.`,

  exemptToNon:
    (endDate, nextDate) =>
      `We will lock all sales up to ${endDate} as per your existing VAT fees exempt status 
      and calculate all sales from this ${nextDate} as per your new NON VAT fees exempt status.`,
};

export const textAlertROI = ({roi_method, initROI}) =>
  roi_method === initROI ? "" : 'We will calculate all sales as per your new ROI method';

export const textAlert = (values = {}, initData = {}) => {
  const {vat_exempt_on_amazon: exempt, is_vat_registered: reg, registration_date: regDateInit } = initData;
  const { registration_date, deregistration_date, vat_exempt_on_amazon, is_vat_registered } = values;
  const isClearInit = !reg && !exempt;
  const isClearNew = !vat_exempt_on_amazon && !is_vat_registered;
  const regDateNew = registration_date && formatDisplayDate(registration_date);
  const regDateNewPrev = registration_date && prevDay(registration_date);
  const deRegDateNew = deregistration_date
    ? formatDisplayDate(deregistration_date)
    : regDateInit !== registration_date
      ?  prevDay(registration_date)
      : currentDay;
  const deRegDateNewNext = deregistration_date ? nextDay(deregistration_date) : nextDay(currentDate);

  let text = "";

  if (isClearInit && is_vat_registered) {
    text = alertVATUpdateOptions["nonToReg"](regDateNewPrev, regDateNew)

  } else if ((reg && isClearNew) || (is_vat_registered && deregistration_date)) {
    text = alertVATUpdateOptions["regToNon"](deRegDateNew, deRegDateNewNext)

  } else if (isClearInit && vat_exempt_on_amazon) {
    text = alertVATUpdateOptions["nonToExempt"](regDateNewPrev, regDateNew)

  } else if (reg && vat_exempt_on_amazon) {
    text = alertVATUpdateOptions["regToExempt"](regDateNewPrev, regDateNew)

  }  else if (exempt && is_vat_registered) {
    text = alertVATUpdateOptions["exemptToReg"](regDateNewPrev, regDateNew)

  }  else if ((exempt && isClearNew) || (vat_exempt_on_amazon && deregistration_date)) {
    text = alertVATUpdateOptions["exemptToNon"](deRegDateNew, deRegDateNewNext)
  }
  return text
};

export const deleteFields = [
  'schemes_new',
  'schemes_historic',
  'schemes_current',
  'global_vat_current',
  'global_vat_historic',
  'roi_method',
  'profit_winner',
  'profit_looser',
];

export const styleList = ["vat_exempt_on_amazon", "is_vat_registered", "registration_date", "deregistration_date", "non_vat_registered" ];

export const CURRENT_TAB_DATA = 0;
