import React from "react";
import Avatar from "@material-ui/core/Avatar";
import TableCell from "@material-ui/core/TableCell";
import TooltipComponentState from "../../components/TooltipComponentState";
import {Link} from "@material-ui/core";
import QuantityCell from './QuantityCell';
import NameCell from './NameCell';
import {
  hasData,
  isNumber,
  sumArray,
  getOrdersToDisplay,
  isEmptyObject,
  arrayOfObjectNullToZeros
} from './utils';

import {formatDate} from '../utils'
import {infoHeaders} from "./infoHeaders";

export const headRows = [
  {
    id: "image_url",
    label: "Product",
    disabled: true,
    render: ({isShipment, id, product, isPrimary}) => (
      <TableCell align="left" key={`${id}--photo`} className="table__cell--photo">
          <TooltipComponentState
            placement="right-start"
            className="cell--photo__tooltip"
            isBlur
            tooltip={
              <Avatar
                alt="img"
                variant="square" src={product.full_image_url} className="cell__tooltip__photo"
              />
            }
          >
            <Link target="_blank" href={product.amazon_product_url}>
              <Avatar alt="img" variant="square" src={product.image_url}/>
            </Link>
          </TooltipComponentState>
      </TableCell>
    )
  },
  {
    id: "item_name",
    render: props => {
      const {product, sku, shipment_id, fnsku, id, isShipment, isPrimary, created_date} = props;
      return (
        <NameCell
          key={`${id}--name`}
          asin={product?.asin1}
          url={product?.amazon_product_url}
          sku={sku}
          title={product?.item_name}
          isShipment={isShipment}
          shipmentId={shipment_id}
          fnsku={fnsku}
          isPrimary={isPrimary}
          createdDate={created_date}
        />)
    }
  },
  {
    id: 'products_ordered',
    label: 'Product Ordered',
    align: "center",
    disabled: true,
    render: props => {
      const {sku, fnsku, product, shipment_id, stage_products_ordered, id, isShipment, total, jorneyId, created_date} = props;
      const stageData = total?.stage_products_ordered || stage_products_ordered || {};
      const {quantity, is_complete, buy_price} = stageData;
      const current_product = {...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}
      const additionalIcon = isShipment && ((!(quantity && buy_price))) ? 'requireAction' : 'complete';
      const iconOrder = {
        'complete': "/media/d-icons/journey/shopping-cart-check.svg",
        'requireAction': "/media/d-icons/journey/shopping-cart.svg",
      };

      return <QuantityCell
        key={`${id}--products_ordered`}
        quantity={quantity}
        product={current_product}
        jorneyId={jorneyId}
        stage="stage_products_ordered"
        icon={iconOrder[additionalIcon]}
        additionalIcon={isShipment && ((!(quantity && buy_price) && 'requireAction') || (is_complete && 'complete'))}
        active
        requireQuantity
        displayData={[{sku, fnsku, buy_price, quantity}]}
        infoTitle="Products Ordered"
        formFields={infoHeaders.products_ordered}
        id={id}
        infoHeadRows={infoHeaders.products_ordered}
      />
    }
  },
  {
    id: 'received_at_prep_center',
    label: 'Product Received',
    align: "center",
    disabled: true,
    render: ({sku, product, shipment_id, stage_received_at_prep_centre, total, id, isShipment, jorneyId, created_date}) => {
      const stageData = total?.stage_received_at_prep_centre || stage_received_at_prep_centre || {};
      const {quantity, damaged, sellable, is_complete} = stageData;
      const fulfilled = [quantity, damaged, sellable].every(isNumber);
      return <QuantityCell
        key={`${id}--received_at_prep_center`}
        requireQuantity
        quantity={quantity}
        product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
        stage="stage_received_at_prep_centre"
        jorneyId={jorneyId}
        id={id}
        active={!isEmptyObject(stage_received_at_prep_centre) || !isShipment}
        icon="/media/d-icons/journey/received-center.svg"
        additionalIcon={isShipment && ((!fulfilled && 'requireAction') || (is_complete && 'complete'))}
        displayData={[{sku, quantity, damaged, sellable}]}
        infoTitle="Received at Prep Center"
        formFields={infoHeaders.received_at_prep_center}
        infoHeadRows={infoHeaders.received_at_prep_center}
      />
    }
  },
  {
    id: 'shipped_to_amazon',
    label: 'Shipped to Amazon',
    align: "center",
    disabled: true,
    render: props => {
      const {product, shipment_id, sku, stage_shipped_to_amazon, total, id, isShipment, created_date} = props;
      const stageData = total?.stage_shipped_to_amazon || stage_shipped_to_amazon || {};

      const {quantity, is_complete, receiving_fulfilment_centre} = stageData;

      return <QuantityCell
        key={`${id}--shipped_to_amazon`}
        active={!isEmptyObject(stage_shipped_to_amazon) || !isShipment}
        quantity={quantity}
        icon="/media/d-icons/fba.svg"
        additionalIcon={isShipment && is_complete && 'complete'}
        infoHeadRows={infoHeaders.shipped_to_amazon}
        displayData={[{sku, quantity, receiving_fulfilment_centre}]}
        infoTitle="Shipped to Amazon"
        product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
      />
    }
  },
  {
    id: 'received_at_amazon',
    label: 'Received at Amazon',
    align: "center",
    disabled: true,
    render: (props, {isDark}) => {
      const {product, shipment_id, sku, stage_received_at_amazon, total, id, isShipment, created_date} = props;
      const stageData = total?.stage_received_at_amazon || stage_received_at_amazon || {};
      const {
        quantity,
        warehouse_damaged_quantity,
        distributor_damaged_quantity,
        customer_damaged_quantity,
        carrier_damaged_quantity,
        expired_quantity,
        defective_quantity,
        is_complete,
      } = stageData;

      const unsellableQuantities = [
        warehouse_damaged_quantity, distributor_damaged_quantity, customer_damaged_quantity,
        carrier_damaged_quantity, expired_quantity, defective_quantity];

      const sellableQuantity = quantity - sumArray(unsellableQuantities);

      return <QuantityCell
        key={`${id}--received_at_amazon`}
        warning={isShipment && hasData(unsellableQuantities)}
        active={!isEmptyObject(stage_received_at_amazon) || !isShipment}
        quantity={quantity}
        icon={isDark
          ? "/media/d-icons/journey/amazonpay-logo-rgb_clr-w.svg"
          : "/media/d-icons/journey/amazonpay-logo-rgb_clr.svg"
        }
        additionalIcon={isShipment && is_complete && 'complete'}
        infoHeadRows={infoHeaders.received_at_amazon}
        displayData={[{...stageData, sku, sellableQuantity}]}
        infoTitle="Received at Amazon"
        product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
      />
    }
  },
  {
    id: 'products_sold',
    label: 'Products Sold',
    align: "center",
    disabled: true,
    render: props => {
      const {product, shipment_id, sku, stage_products_sold, total, id, isShipment, is_complete, created_date} = props;
      const stageData = total?.stage_products_sold || stage_products_sold || [];
      const sold_quantity = sumArray(stageData.map(el => el.quantity));

      return (
        <QuantityCell
          key={`${id}--products_sold`}
          quantity={sold_quantity}
          active={!!stage_products_sold || !isShipment}
          icon="/media/d-icons/journey/tag 1.svg"
          additionalIcon={isShipment && is_complete && 'complete'}
          infoHeadRows={infoHeaders.products_sold}
          infoTitle="Products Sold"
          displayData={getOrdersToDisplay(stage_products_sold)}
          product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
        />
      )
    }
  },
  {
    id: 'products_refunded',
    label: 'Products Refunded',
    align: "center",
    disabled: true,
    render: props => {
      const {product, shipment_id, sku, stage_products_refunded, total, id, isShipment, created_date} = props;
      const stageData = total?.stage_products_refunded || stage_products_refunded || [];
      const refunded_quantity = sumArray(stageData.map(el => el.quantity))
      return <QuantityCell
        key={`${id}--products_refunded`}
        quantity={refunded_quantity}
        active={!!stage_products_refunded || !isShipment}
        icon="/media/d-icons/journey/product-refunded.svg"
        infoHeadRows={infoHeaders.products_refunded}
        info={getOrdersToDisplay(stageData)}
        infoTitle="Products Refunded"
        displayData={getOrdersToDisplay(stage_products_refunded)}
        product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
      />
    }
  },
  {
    id: 'stranded_inventory',
    label: 'Stranded Inventory',
    align: "center",
    disabled: true,
    render: ({product, shipment_id, sku, stage_stranded_inventory, total, id, isShipment, created_date}) => {
      const stageData = total?.stage_stranded_inventory || stage_stranded_inventory || {};
      const {quantity, reasons_for_stranded} = stageData;
      return <QuantityCell
        key={`${id}--stranded_inventory`}
        quantity={quantity}
        active={!isEmptyObject(stage_stranded_inventory) || !isShipment}
        icon="/media/d-icons/journey/Stranded_Inventory.svg"
        infoHeadRows={infoHeaders.stranded_inventory}
        info={[[quantity, reasons_for_stranded && reasons_for_stranded.join(', ')]]}
        displayData={[
          {
            quantity,
            ...(reasons_for_stranded
              ? {reasons_for_stranded: reasons_for_stranded.join(', ')}
              : {}
              )}
        ]}
        infoTitle="Stranded Inventory"
        product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
      />
    }
  },
  {
    id: 'return_received',
    label: 'Return Received',
    align: "center",
    disabled: true,
    render: ({product, shipment_id, sku, stage_returns_received, total, id, isShipment, created_date}) => {
      const stageData = total?.stage_returns_received || stage_returns_received || {};
      const {quantity, orders = []} = stageData;

      return <QuantityCell
        key={`${id}--return_received`}
        quantity={quantity}
        active={!isEmptyObject(stage_returns_received) || !isShipment}
        icon="/media/d-icons/journey/return-received.svg"
        infoHeadRows={infoHeaders.return_received}
        displayData={getOrdersToDisplay(orders)}
        infoTitle="Return Received"
        product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
      />
    }
  },
  {
    id: 'return_back_to_inventory',
    label: 'Return Back In Inventory',
    align: "center",
    disabled: true,
    render: ({product, shipment_id, sku, stage_returns_back_to_inventory, total, id, isShipment, created_date}) => {
      const stageData = total?.stage_returns_back_to_inventory || stage_returns_back_to_inventory || {};
      const {quantity, orders = []} = stageData;
      // console.log(getOrdersToDisplay(orders));

      return <QuantityCell
        key={`${id}--return_back_to_inventory`}
        quantity={quantity}
        active={!isEmptyObject(stage_returns_back_to_inventory) || !isShipment}
        icon="/media/d-icons/journey/trolley.svg"
        infoHeadRows={infoHeaders.return_back_to_inventory}
        displayData={getOrdersToDisplay(orders)}
        infoTitle="Return Back In Inventory"
        product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
      />
    }
  },
  // {
  //   id: 'asin_inventory_overview',
  //   label: 'ASIN Inventory Overview',
  //   align: "center",
  //   disabled: true,
  //   render: props => {
  //     const {product, shipment_id, sku, inventory_overview, isPrimary, id, created_date} = props;
  //     const stageData = inventory_overview || {};
  //     const {total_inventory} = stageData;
  //
  //     return <QuantityCell
  //       key={`${id}--asin_inventory_overview`}
  //       isShipment={!isPrimary}
  //       quantity={total_inventory}
  //       active
  //       icon="/media/d-icons/journey/list 1.svg"
  //       infoHeadRows={infoHeaders.asin_inventory_overview}
  //       displayData={arrayOfObjectNullToZeros([stageData])}
  //       infoTitle="ASIN Inventory Overview"
  //       product={{...product, shipment_id, seller_sku: sku, createdDate: formatDate(created_date)}}
  //     />
  //   }
  // },
];
